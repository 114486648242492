/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { auscultationPoints, AuscultationPointType, Sex } from "@medaica/common/const"
import { Transform, Exclude, Type } from "class-transformer"
import { getConfig } from "@medaica/common/services/util"
import { buildAuscultationFileName } from "@medaica/common/services/factories"
import { NoiseQualityRecord } from "../components/audio-player/noise-quality-recorder"

const TransformDate = () => {
  const toPlain = Transform(({ value }) => (value as Date).toString(), {
    toPlainOnly: true,
  })

  const toClass = Transform(
    ({ value }) => {
      if (typeof value === "string") {
        return value ? new Date(value) : null
      } else {
        return value as Date
      }
    },
    {
      toClassOnly: true,
    }
  )

  // eslint-disable-next-line @typescript-eslint/ban-types
  return function (target: Object, key: string) {
    toPlain(target, key)
    toClass(target, key)
  }
}

// Converts a YYYY-MM-DD string from json into a Date object and converts a Date object into YYYY-MM-DD string
const TransformDateNoTime = () => {
  const toDateString = (date: Date | undefined | null): string | null => {
    if (!date) {
      return null
    }
    const day = date.getDate().toString().padStart(2, "0")
    const month = (date.getMonth() + 1).toString().padStart(2, "0")
    const year = date.getFullYear()
    return `${year}-${month}-${day}`
  }

  const toPlain = Transform(({ value }) => toDateString(value as Date), {
    toPlainOnly: true,
  })

  const toClass = Transform(
    ({ value }) => {
      return value ? new Date(`${value} 00:00:00`) : null
    },
    {
      toClassOnly: true,
    }
  )

  // eslint-disable-next-line @typescript-eslint/ban-types
  return function (target: Object, key: string) {
    toPlain(target, key)
    toClass(target, key)
  }
}

const TransformSex = () => {
  const toPlain = Transform(
    ({ value }: { value: Sex | null }) => {
      return value ? value.toString() : null
    },
    {
      toPlainOnly: true,
    }
  )

  const toClass = Transform(
    ({ value }: { value: "male" | "female" | null }) => {
      return value ? Sex[value] : null
    },
    {
      toClassOnly: true,
    }
  )

  // eslint-disable-next-line @typescript-eslint/ban-types
  return function (target: Object, key: string) {
    toPlain(target, key)
    toClass(target, key)
  }
}

const TransformAuscultationPoint = () => {
  const toPlain = Transform(
    ({ value }: { value: AuscultationPoint }) => {
      return value.label
    },
    {
      toPlainOnly: true,
    }
  )

  const toClass = Transform(
    ({ value }: { value: "string" }) => {
      return auscultationPoints.getByLabel(value)
    },
    {
      toClassOnly: true,
    }
  )

  // eslint-disable-next-line @typescript-eslint/ban-types
  return function (target: Object, key: string) {
    toPlain(target, key)
    toClass(target, key)
  }
}

type MedaicaApiAccessToken = {
  expiresAt: Date
  value: string
}

type FirebaseAccessToken = {
  value: string
  expires_in: Date
}

type TwilioAccessToken = {
  value: string
  expires_in: Date
}

type TokenData = {
  value: string
  tokenType: string
  expiresIn: number
  userId: string
}

type SelfExamAccessTokenData = {
  selfExamRequestId: string
} & TokenData

type LiveExamAnonAccessToken = {
  value: string
  tokenType: string
  expiresAt: Date
  userId: string
  userName: string
  liveExamId: string
}

type Page<T> = {
  page?: number // 1 based, so 1 is the first page, not 0.
  pageSize?: number
  totalCount?: number
  items: T[]
}

class VirtualExam {
  @Exclude({ toPlainOnly: true })
  id: string
  @TransformDate()
  @Exclude({ toPlainOnly: true })
  dateCreated: string
  @Type(() => HealthcareProvider)
  healthcareProvider: HealthcareProvider
  @Type(() => PatientProfile)
  patientProfile: PatientProfile
  @TransformDate()
  scheduledStartDate: Date
  @TransformDate()
  scheduledEndDate: Date
  examId: string
  @Transform(({ value }: { value: string }) => `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6)}`)
  accessCode: string
  @Type(() => Exam)
  exam: Exam

  get link(): string {
    return `${getConfig("PATIENT_PORTAL_URL")}/virtual-exam/${this.id}`
  }
}

class PatientProfile {
  @Exclude({ toPlainOnly: true })
  id: string
  firstName: string
  lastName: string
  @TransformDate()
  @Exclude({ toPlainOnly: true })
  // todo convert to date
  dateCreated: string
  emailAddress: string
  @TransformDateNoTime()
  birthdate: Date | null
  @TransformSex()
  sex: Sex | null
  healthcareProviderId: string
  organizationId: string
  healthcareProvider: HealthcareProvider

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }
}

class SelfExamRequest {
  id: string
  patientProfileId: string
  @Type(() => PatientProfile)
  patientProfile: PatientProfile
  examDate: Date
  dateCreated: Date
  instructions: string
  @Type(() => SelfExamRequestAuscultationRequest)
  auscultationRequests: SelfExamRequestAuscultationRequest[]
  healthcareProviderId: string
  url: string
  examId?: string
  @Type(() => Exam)
  exam?: Exam
}

class SelfExamRequestAuscultationRequest {
  id: string
  @TransformAuscultationPoint()
  auscultationPoint: AuscultationPoint
  @Type(() => Auscultation)
  auscultation: Auscultation | null
}

class HealthcareProvider {
  id: string
  emailAddress: string
  firstName: string
  lastName: string
  displayName: string
  mustChangePassword: boolean
  tcVersion: number

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }
}

class HealthcareProviderOverview {
  id: string
  emailAddress: string
  firstName: string
  lastName: string
  displayName: string
  roles: string[]
  mustChangePassword: boolean
  tcVersion: number
  totalExams: number
  totalPatients: number
  totalUnreviewedExams: number

  get fullName(): string {
    return `${this.firstName ?? ""} ${this.lastName ?? ""}`
  }
}

type Organization = {
  id: string
  name: string
}

class HealthcareProviderInvitation {
  id: string
  emailAddress: string
  used: boolean
  active: boolean
  dateExpires: Date
  organization: Organization

  get isValid(): boolean {
    return !(this.used || !this.active || this.dateExpires < new Date())
  }
}

class Patient {
  id: string
  firstName: string
  lastName: string
  mustChangePassword: boolean
  tcVersion: number

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }
}

type RegistrationResponse = {
  id: string
  auth0UserId: string
  email: string
}

class Auscultation {
  id: string
  @TransformDate()
  dateCreated: Date
  examId: string
  deviceLabel: string
  @TransformAuscultationPoint()
  auscultationPoint: AuscultationPoint
  recording: Recording
  noiseQualityRecord: NoiseQualityRecord
  heartrateBpm: number | null
  heartbeatPresent: boolean | null
  heartbeatNormal: boolean | null
  ausculthingDiagnosis: number | null

  get fileName(): string {
    return buildAuscultationFileName(this.auscultationPoint, this.dateCreated, this.recording.mimeType)
  }
}

type Recording = {
  id: string
  dateCreated: Date
  auscultationId: string
  duration: number
  mimeType: string
  fileName: string
  data?: Blob
}

enum ExamType {
  Live = "Live",
  SelfHealthcareProviderInitiated = "Self - Provider Initiated",
  SelfPatientInitiated = "Self - Patient Initiated",
}

type TurnCredential = { url: string; urls: string; credential?: string; username?: string }

class Exam {
  id: string
  patientId: string | null
  dateCreated: Date
  @Type(() => Auscultation)
  auscultations: Auscultation[] = []
  @Type(() => PatientProfile)
  patientProfile: PatientProfile
  patient: Patient
  healthcareProvider: HealthcareProvider
  notes: string
  enhancedNotes: string[]
  reviewed: boolean
  type: ExamType

  get patientFullName(): string {
    return this.patientProfile ? this.patientProfile.fullName : this.patient.fullName
  }
}

type AuscultationPoint = {
  label: string
  longLabel: string
  description: string
  type: AuscultationPointType
}

class ExamPrevNextIds {
  prevId: string | null
  nextId: string | null
  totalExams: number
  examSequenceNumber: number
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyError = any

export type {
  RegistrationResponse,
  MedaicaApiAccessToken,
  FirebaseAccessToken,
  TwilioAccessToken,
  LiveExamAnonAccessToken,
  SelfExamAccessTokenData,
  Page,
  Recording,
  TurnCredential,
  AnyError,
  Organization,
  AuscultationPoint,
}

export {
  Patient,
  HealthcareProvider,
  HealthcareProviderOverview,
  VirtualExam,
  PatientProfile,
  Exam,
  Auscultation,
  SelfExamRequest,
  SelfExamRequestAuscultationRequest,
  HealthcareProviderInvitation,
  ExamPrevNextIds,
}
